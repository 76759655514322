import React from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adBannersActions from '../actions/adBannersActions';
import PropTypes from "prop-types";
import InlineInputSubscription from '../subscriptions/inlineInput';
import InjectInsideHtmlComponent from "../base/injectInsideHtmlComponent";
import {ParseShortcodeAttrsForInlineInput} from "../subscriptions/shortcodeUtils";
import {parseCookies, setCookie} from "../base/cookieHelper";
import AdBannersApi from "../api/adBanners";

class AdRender extends React.Component{

    state= {
        bannerScriptLoaded: false,
        id: Math.random().toString(36).substring(7)
    };
    bannerRef = React.createRef();

    componentDidMount() {
        this.processBannerScript();
        this.detectElementVisibility();
    }

    componentDidUpdate() {
        this.processBannerScript();
        this.detectElementVisibility();
    }

    shouldComponentUpdate(nextProps){
        return (!this.props.banner && !!nextProps.banner);
    }

    detectElementVisibility = () => {

        if(!this.props.banner){
            return;
        }
        const observer = new IntersectionObserver(([entry]) => {
            if(entry.isIntersecting === true){
                this.trackImpression(this.props.banner.id);
                observer.disconnect();
            }

        });
        observer.observe(this.bannerRef.current);
    };

    trackImpression = (bannerId) => {
      let showedBanners = parseCookies('ca_ads', []);
      if (showedBanners.indexOf(bannerId) < 0){
          showedBanners.push(bannerId);
          setCookie('ca_ads', showedBanners);
          AdBannersApi.trackImpression(bannerId);
      }
    };

    trackClick = (e) => {
        let clickedBanners = parseCookies('ca_ads_c', []);
        if (!!this.props.banner.id && clickedBanners.indexOf(this.props.banner.id) < 0 && e.target.matches('a, a *')) {
            clickedBanners.push(this.props.banner.id);
            setCookie('ca_ads_c', clickedBanners);
            AdBannersApi.trackClick(this.props.banner.id)
        }
    };

    processBannerScript = () => {
        if (this.props.banner && !this.state.bannerScriptLoaded){
            let element = document.getElementById(this.state.id);
            if (!!element){
                Array.from(element.getElementsByTagName('SCRIPT')).forEach(function(element) {
                    const script = element.text;
                    window.eval(script);
                });
            }
            this.setState({bannerScriptLoaded: true});
        }
    };

    renderAd(){
        if(!this.props.banner){
            return null;
        }

        const htmlCode = this.props.banner.code;

        if (this.props.banner.newsletter_form_attrs) {
            const parsedAttrs = ParseShortcodeAttrsForInlineInput(htmlCode, {...this.props.banner.newsletter_form_attrs});
            return <InjectInsideHtmlComponent regex={/\[InlineForm.[^\]]*\]/i} passedComponent={InlineInputSubscription}
                                              html={this.props.banner.code} passedAttrs={parsedAttrs} />
        }

        return <div id={this.state.id} ref={bannerElement => (this.bannerElement = bannerElement)}
                    className={this.props.className}
                    dangerouslySetInnerHTML={{__html: htmlCode}} />;
    }

    render() {
        return (
            <div ref={this.bannerRef} onClick={this.trackClick}>
                {this.renderAd()}
            </div>)
    }
}
AdRender.propTypes = {
    adBanners: PropTypes.object

};

function mapStateToProps(state, ownProps) {
    let banner = null;

    if(ownProps.code){
        banner = state.adBanners.collection.filter((ad) => ad.slot_code === ownProps.code)[0];
    } else {
        let randElement = Math.floor(Math.random() * state.adBanners.collection.length);
        banner = state.adBanners.collection[randElement];
    }

    return {
        banner: banner
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, adBannersActions), dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AdRender);
