import React, {Component, Fragment} from "react";
import {actions} from "react-fields";
import Carousel from './carousel';

export default class TeamTabs extends Component {

    state = {
        isLoaded: false,
        currentTab: 'team',
        collection: {}
    };

    componentDidMount() {
        let _this = this;
        fetch(actions.getData('/api/team_members'))
            .then(actions.status)
            .then(actions.json)
            .then(function(data) {
                return data;
            }).then( async (response) => {
            _this.setState({collection: response, isLoaded: true})
        }).catch(error => {
            console.log(error);
        })
    }

    render() {
        if (!this.state.isLoaded) {
            return null;
        }

        return(
            <Fragment>
                <Carousel key={'teamCarousel'} collection={this.state.collection['team']} />
            </Fragment>
        )
    }

}