export function ParseShortcodeAttrsForInlineInput(html, attrs) {
    const btnTxtMatch = html.match(/\[InlineForm.*btn_text='(.*?)'.[^\]]*\]/i);
    if (btnTxtMatch && btnTxtMatch[1]) { attrs['btnText'] = btnTxtMatch[1] }
    const subTxtMatch = html.match(/\[InlineForm.*sub_text='(.*?)'.[^\]]*\]/i);
    if (subTxtMatch && subTxtMatch[1]) { attrs['subText'] = subTxtMatch[1] }
    if (html.match(/\[InlineForm.*dark.[^\]]*\]/i)) { attrs['dark'] = true }
    if (html.match(/\[InlineForm.*white.[^\]]*\]/i)) { attrs['white'] = true }
    if (html.match(/\[InlineForm.*secondary.[^\]]*\]/i)) { attrs['secondary'] = true }
    if (html.match(/\[InlineForm.*icon.[^\]]*\]/i)) { attrs['icon'] = true }
    if (html.match(/\[InlineForm.*skip_sub_text.[^\]]*\]/i)) { attrs['skipSubText'] = true }
    return attrs
}