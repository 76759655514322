import React, {Fragment} from "react";

export const ArticleCardSmall = ({article}) => {

    return (
        <div className="grid-x grid-margin-x grid-margin-y mar-bot-20">
            <div className={'cell medium-shrink text-center'}>
                <a href={article.path}>
                    <img alt={article.name} src={article.image_small_url} loading={'lazy'} className={'article__sidebar-img'} />
                </a>
            </div>
            <div className={'cell medium-auto'}>
                <a href={article.path} className={'article__sidebar-lnk'}>{article.name}</a>
                <p className='fnt-13 fnt-light clr-grey-a5 mar-0'>{article.publish_date_formatted || article.publish_date}</p>
            </div>
        </div>
    )

};