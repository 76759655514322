import {actions} from 'react-fields';

export default class SubscriptionApi {

    static submit(form, categories, token= null) {
        let url = form.encrypted_key ? `/api/subscriptions/${form.encrypted_key}` : '/api/subscriptions';
        /// remove categories
     //   let categoriesSettings = {};
      //  categories.map((cat) => categoriesSettings[`category_${cat.id}`] = cat.selected);
      //  let data = {settings: categoriesSettings};
        let data = {};
        if(!form.encrypted_key){
            data = {...data, email: form.email, form_type: form.form_type, source_url: document.location.href,
                newsletter_form_id: form.newsletter_form_id, ad_banner_id: form.ad_banner_id};
        }
        return fetch(form.encrypted_key ?
            actions.putRequest(url, {subscription: data}) :
            actions.postRequest(url, {subscription: data, g_recaptcha_response: token}))
            .then(actions.status)
            .then(actions.json)
    }

    static validate(form, categories, onSuccess, onError) {
        let errors = {};

        if (!form.email)
            errors['email'] = `This field is required`;

        // if (!form.name)
        //     errors['name'] = `This field is required`;
        //
        // if (!form.terms_agreement && !form.encrypted_key)
        //     errors['termsAgreement'] = `You need to accept the Terms and Privacy`;

        if (Object.keys(errors).length) {
            onError(errors);
        } else {
            onSuccess();
        }
    }

}